import React, {useState} from 'react'

import translate from 'react-i18next/dist/commonjs/translate'
import {
  formatDateWithSeparator,
  formatDateShortMonth,
  formatTime
} from '../../../utils/dateFormatting'
import cookies from 'browser-cookies'

const TaskTemplate = ({item, t}) => {
  const [showAll, setShowAll] = useState(false)

  const translation = t(item?.headerTitle) || ''
  const footer = t(item?.footer?.title) || ''

  const [beforeSender, afterSender = ''] = translation.split('sender_name')
  const [beforeDate, afterDate = ''] = afterSender.split('created_date')
  const [beforeUrl, afterUrl = ''] = footer.split('link_to')

  const contentValue = (value) => {
    const translationKeyRegex = /^[a-zA-Z]+(\.[a-zA-Z_]+)+$/
    if (typeof value === 'number') {
      return parseFloat(value).toLocaleString('en')
    }

    if (translationKeyRegex.test(value)) {
      return t(value)
    }

    return value?.replace(/@\[(.*?)\]\(.*?\)/g, '@$1')
  }

  const toggleShowAll = () => setShowAll(!showAll)

  return (
    <div>
      <div
        style={{marginBottom: '6px', fontSize: '14px'}}
        id='notifHeaderTitleContainer'
      >
        {beforeSender}{' '}
        <strong className='notifSender' id='notifHeaderTitleSender'>
          {item.senderName}
        </strong>{' '}
        {beforeDate}
        <strong className='notifCreatedDate' id='notifHeaderTitleDate'>
          {formatDateWithSeparator(new Date(item.createdAt))}
        </strong>{' '}
        {afterDate}{' '}
        <strong className='notifCreatedHours' id='notifHeaderTitleHours'>
          {formatTime(new Date(item.createdAt))}
        </strong>
      </div>
      {(item?.detail || [])
        ?.slice(0, showAll ? item?.detail?.length : 3)
        ?.map((detailNotif) => (
          <>
            <div className='detailNotifContainer' id='notifContentContainer'>
              <div className='detailNotifLabel'>
                <p
                  className='detailText notifContentLabel'
                  id='notifContentLabel'
                >
                  {t(detailNotif?.label)}
                </p>
                <p style={{margin: '0'}}>:</p>
              </div>
              {detailNotif?.url ? (
                <a
                  href={detailNotif?.url}
                  className='detailTextLink notifContentValue'
                  id='notifContentValue'
                >
                  {contentValue(detailNotif?.value)}
                </a>
              ) : (
                <p
                  className='detailText notifContentValue'
                  id='notifContentValue'
                >
                  {contentValue(detailNotif?.value)}
                </p>
              )}
            </div>
            {detailNotif?.subDetail?.map((subDetailNotif) => (
              <div
                key={subDetailNotif?.label}
                className='detailNotifContainer'
                id='notifSubContentContainer'
              >
                <div className='detailNotifLabel'>
                  <p
                    className='detailText notifContentLabel'
                    style={{paddingLeft: '10px'}}
                    id='notifSubContentLabel'
                  >
                    {t(subDetailNotif?.label)}
                  </p>
                  <p style={{margin: '0'}}>:</p>
                </div>
                {subDetailNotif?.url ? (
                  <a
                    href={subDetailNotif?.url}
                    className='detailTextLink notifContentValue'
                    id='notifSubContentValue'
                  >
                    {contentValue(subDetailNotif?.value)}
                  </a>
                ) : (
                  <p
                    className='detailText notifContentValue'
                    id='notifSubContentValue'
                  >
                    {contentValue(subDetailNotif?.value)}
                  </p>
                )}
              </div>
            ))}
          </>
        ))}
      {item?.footer !== null && showAll && (
        <p className='footerText' id='footerText'>
          {beforeUrl}{' '}
          <a
            href={item?.footer?.url}
            className='footerTextLink'
            id='footerTextLink'
          >
            {cookies.get('locale') === 'id' ? 'disini' : 'here'}
          </a>
          {afterUrl}
        </p>
      )}
      <div
        className='timestamp'
        style={{color: '#4A4A4A', fontSize: '12px'}}
        id='notifTimestamp'
      >
        {formatTime(new Date(item.createdAt))} WIB,{' '}
        {formatDateShortMonth(new Date(item.createdAt))}
      </div>
      {item?.detail?.length > 3 && (
        <div
          onClick={() => toggleShowAll()}
          className='buttonView'
          id='notifButtonView'
        >
          {showAll
            ? t('notifications.button.view_less')
            : t('notifications.button.view_more')}
        </div>
      )}
    </div>
  )
}

export const buildActionLink = () => null

export const Task = translate()(TaskTemplate)

export default {
  Template: Task,
  buildActionLink
}
