import React, {Component} from 'react'

const withNotificationTemplate = ({Icon, Template, buildActionLink}) => (
  WrappedComponent
) => {
  class WithNotificationTemplate extends Component {
    render() {
      return (
        <WrappedComponent
          icon={Icon}
          buildActionLink={buildActionLink}
          {...this.props}
        >
          <Template {...this.props} />
        </WrappedComponent>
      )
    }
  }

  return WithNotificationTemplate
}

export default withNotificationTemplate
