import * as types from './types'
import notificationInboxDefault from './initialState'

const reducers = {
  [types.notificationInboxOpen]: (state) => ({
    ...state,
    visible: true
  }),
  [types.notificationInboxNotShow]: (state) => ({
    ...state,
    visible: false
  }),
  [types.notificationInboxClose]: (state) => ({
    ...state,
    visible: false,
    items: state.items.map((item) => ({
      ...item,
      isRead: true
    }))
  }),
  [types.notificationInboxSetFilter]: (state, action) => ({
    ...state,
    filter: action.payload
  }),
  [types.notificationInboxDataLoaded]: (state, action) => ({
    ...state,
    loading: false,
    items: [...action.payload]
  }),
  [types.notificationInboxArchiveMessage]: (state, action) => ({
    ...state,
    items: state.items.filter((item) => item.id !== action.payload)
  }),
  [types.notificationInboxLoadingMessages]: (state) => ({
    ...state,
    loading: true
  }),
  [types.notificationInboxAddMessage]: (state, action) => ({
    ...state,
    items: [action.payload, ...state.items]
  })
}

export default reducers
