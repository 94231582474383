import {getCountryConfig} from '../../../config/country'
let countryConfig = getCountryConfig()

export const deleted = ['deleted']
export const created = ['id']

export const appointmentFields = [
  'id',
  'bookingId',
  'leadId',
  'date',
  'time',
  'inspectionPointCity',
  'inspectionPointName',
  'rejectionReasons',
  'bookingSource',
  'createdAt',
  'createdByUserEmail',
  'updatedAt',
  'updatedByUserEmail',
  'placeId',
  'status',
  'comments',
  'cancellationReason',
  'callStatus',
  'cancelledInStatus',
  'cancelledAt',
  'assignedTo',
  'assignedToName',
  'type',
  'service',
  'address',
  'purchaseCoordinator',
  'lat',
  'lng',
  'newCarSalesName',
  'newCarSalesEmail',
  'newCarSalesPhoneNumber',
  'newCarSalesNRP',
  ['participants', ['id', 'firstname', 'lastname', 'role']]
]

export const latestAppointmentFields = ['id', 'bookingId']

const turkeyOptions = ['faceLift', 'seat', 'engine', 'wheelDrive', 'bodyType']

export const leadFields = [
  ...(countryConfig.countryCode === 'TR' ? turkeyOptions : ''),
  'id',
  'country',
  'make',
  'model',
  'leadSource',
  'externalLeadId',
  'serviceType',
  'trim',
  'plate',
  'transmission',
  'year',
  'mileage',
  'price',
  'minPrice',
  'maxPrice',
  'responsible',
  'responsibleName',
  'status',
  'rejectionReasons',
  'createdAt',
  'createdByUserEmail',
  'updatedAt',
  'updatedByUserEmail',
  'discardedReason',
  'referralCode',
  'ownerName',
  'ownerPhone',
  'VINnumber',
  'inspectionType',
  'selfInspection',
  'selfInspectionPrice',
  ['appointments', appointmentFields],
  'adId',
  ['latestAppointment', latestAppointmentFields]
]

export const leadListAppointmentFields = [
  'id',
  'bookingId',
  'date',
  'time',
  'inspectionPointName',
  'rejectionReasons',
  'bookingSource',
  'createdAt',
  'createdByUserEmail',
  'status',
  'comments',
  'cancellationReason',
  'callStatus',
  'cancelledInStatus',
  'cancelledAt',
  'assignedToName',
  'type',
  'address',
  'lat',
  'lng',
  ['participants', ['id', 'firstname', 'lastname', 'role']]
]

export const leadListContactFields = [
  'id',
  'firstname',
  'lastname',
  'phone',
  'city'
]

export const leadListFields = [
  'id',
  'country',
  'make',
  'model',
  'leadSource',
  'serviceType',
  'trim',
  'plate',
  'transmission',
  'year',
  'mileage',
  'price',
  'minPrice',
  'maxPrice',
  'responsibleName',
  'status',
  'rejectionReasons',
  'createdAt',
  'createdByUserEmail',
  'updatedAt',
  'updatedByUserEmail',
  'discardedReason',
  'referralCode',
  'ownerName',
  'ownerPhone',
  'VINnumber',
  'inspectionType',
  'selfInspectionPrice',
  ['appointments', leadListAppointmentFields],
  'adId',
  ['contact', leadListContactFields]
]

export const finalLeadListFields = ['count', ['list', leadListFields]]

export const carLeadCallback = ['contactId']

export const leadOptions = ['leadSource']

export const contactFields = [
  'id',
  'country',
  'locale',
  'salutation',
  'firstname',
  'lastname',
  'email',
  'phone',
  'phone2',
  'address',
  'createdAt',
  'updatedAt',
  'smsMarketing',
  'emailMarketing',
  'createdByUserEmail',
  'city',
  'postcode',
  'olxUserId'
]

export const contactWithLeads = [...contactFields, ['leads', leadFields]]

export const commentFields = [
  'id',
  'contactId',
  'type',
  'subtype',
  'subject',
  'status',
  'dueDate',
  'priority',
  'responsible',
  'responsibleName',
  'description',
  'createdAt',
  'createdByUserEmail'
]

export const changesFields = [
  'id',
  'type',
  'typeId',
  'field',
  'value',
  'operation',
  'updatedById',
  'updatedAt',
  'updatedByEmail'
]

export const contactAllFields = [
  ...contactWithLeads,
  ['comments', commentFields]
]

export const commentAllFields = [...commentFields, ['contact', contactFields]]

export const leadWithContactFields = [...leadFields, ['contact', contactFields]]

export const appointmentAllFields = [
  ...appointmentFields,
  'createdAt',
  'createdByUserEmail',
  ['lead', leadWithContactFields]
]

export const contactDetail = ['count', ['list', contactFields]]

export const contactList = ['count', ['list', contactAllFields]]

export const commentList = ['count', ['list', commentFields]]

export const taskDetailList = ['count', ['list', commentAllFields]]

export const changesList = ['count', ['list', changesFields]]

export const leadList = ['count', ['list', leadWithContactFields]]

export const appointmentList = ['count', ['list', appointmentAllFields]]

// Tiers
export const leadAssignment = ['id', 'userId', 'tierId', 'leadId']
export const tierCriteriaValueInput = [
  'make',
  'leadSource',
  'status',
  'email',
  'phone',
  ...(countryConfig === 'TR' ? ['appointmentStatus', 'placeId'] : '')
]

export const tierCriteria = ['id', 'name', ['value', tierCriteriaValueInput]]

export const tier = [
  'id',
  'name',
  'action',
  'weight',
  'userRoleId',
  'isFinite',
  'maxAssignments',
  'isEnabled',
  ['tierCriteria', tierCriteria],
  ['leadAssignments', leadAssignment]
]

export const tierList = [['list', tier]]

export const tierCriteriaList = [['list', tierCriteria]]

export const INBOX_FIELDS = [
  'id',
  'referenceId',
  'referencedEntity',
  'metaInfo',
  'senderId',
  'senderName',
  'recipientId',
  'country',
  'archived',
  'createdAt',
  'updatedAt',
  'type',
  'detail',
  'headerTitle',
  'footer'
]
