import {Component} from 'react'
import React from 'react'
import NotificationIcon from '../../assets/images/icons/Notification.svg'
import {notificationInboxToggle} from '../../store/newNotifications/actions'
import {connect} from 'react-redux'

export class NotificationButton extends Component {
  constructor(props) {
    super(props)
    this.onButtonPress = this.onButtonPress.bind(this)
  }
  onButtonPress(event) {
    event.preventDefault()
    this.props.notificationInboxToggle()
  }

  render() {
    const className = `notificationButton bell ${
      this.props.hasUnread === true ? 'ring' : ''
    }`
    return (
      <>
        <a
          href='#'
          className={className}
          onClick={this.onButtonPress}
          id='headerNotificationButtonContainer'
        >
          <NotificationIcon
            width='24'
            height='24'
            id='headerNotificationIcon'
          />
          {this.props.hasUnread === true ? (
            <div
              className='notificationIndicator'
              id='headerNotificationNumberContainer'
            >
              <p
                className='notificationIndicatorNumber'
                id='headerNotificationNumberText'
              >
                {this.props.unreadInboxValue?.length > 99
                  ? '99+'
                  : this.props.unreadInboxValue?.length}
              </p>
            </div>
          ) : null}
        </a>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  hasUnread:
    state.notificationInbox.items.filter(
      (item) => !item.isRead && !item.archived
    ).length > 0,
  unreadInboxValue: state.notificationInbox.items.filter(
    (item) => !item.isRead && !item.archived
  )
})

const mapDispatchToProps = (dispatch) => ({
  notificationInboxToggle: () => dispatch(notificationInboxToggle())
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton)
