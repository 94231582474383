import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import isEmpty from 'lodash/isEmpty'
import createSagaMiddleware from 'redux-saga'

import {rootSagas} from './core'

import {
  getInitialState as getResourcesInitialState,
  reducers as ResourcesReducers
} from './resources'

import {
  getInitialState as getConfigInitialState,
  reducers as ConfigReducers
} from './config'

import {
  getInitialState as getAuthInitialState,
  reducers as AuthReducers
} from './auth'

// import {
//   getInitialState as getCarDataInitialState,
//   reducers as CarDataReducers
// } from './cardata'

import {
  getInitialState as getCategoryInitialState,
  reducers as CategoryReducers
} from './category'

import {
  getInitialState as getBulkUploadInitialState,
  reducers as BulkUploadReducers
} from './bulkUpload'

// import {
//   getInitialState as getCarDataFilesInitialState,
//   reducers as carDataFilesReducers
// } from './cardata/carDataFiles'

import {
  getInitialState as getGlobalInitialState,
  reducers as GlobalReducers
} from './global'

import {
  getInitialState as getSignalInitialState,
  reducers as SignalReducers
} from './signals'

import {
  reducers as InspectionReportsReducers,
  getInitialState as getInspectionReportsInitialState
} from './inspectionReports'

import {
  getInitialState as getUserInitialState,
  reducers as UserReducers
} from './users'

import {
  getInitialState as getParticipantInitialState,
  reducers as participantReducers
} from './crm/participant'

import {default as crmSetup} from './crm'

import {
  getInitialState as getLocationInitialState,
  reducers as locationReducers
} from './locationManagement'

import {
  getInitialState as getCalendarInitialState,
  reducers as CalendarReducers
} from '../components/Pickers/Calendar/store'

import {
  getInitialState as getAppointmentCalendarInitialState,
  reducers as AppointmentCalendarReducers
} from './calendar'

import {
  getInitialState as getDocumentConfigInitialState,
  reducers as DocumentConfigReducers
} from './documentConfig'

import {
  getInitialState as getCRMUserGroupsIntialState,
  reducers as CRMUserGroupsReducers
} from './crmUserGroups'

import {
  getInitialState as getFinancingUserGroupsInitialState,
  reducers as financingUserGroupsReducers
} from './financingUserGroups'

import {
  getInitialState as getWebhookInitialState,
  reducers as WebhookReducers
} from './webhook'

import {
  getInitialState as getPayslipsIntialState,
  reducers as PayslipsReducer
} from './payslips/list'

import {
  getInitialState as getPayslipsNewIntialState,
  reducers as CreatePayslipReducer
} from './payslips/create'

import {
  getInitialState as getCountryOptionIntialState,
  reducers as CountryOptionReducer
} from './countryOption'

import {
  getInitialState as getDriverIntialState,
  reducers as DriverReducer
} from './driver'

import {
  getInitialState as getTransitJobIntialState,
  reducers as TransitJobReducer
} from './transitJob'

import {
  getInitialState as getOwnershipTransferState,
  reducers as ownershipTransfers
} from './carOwnership'

import {
  getInitialState as getAuctionsSchedulesInitialState,
  reducers as AuctionsSchedulesReducer
} from './auctionSchedules'

import {
  getInitialState as getBusinessPartnerInitialState,
  reducers as BusinessPartnerReducers
} from './businessPartners'

import {
  getInitialState as getNotificationsInitialState,
  reducers as NotificationsReducers
} from './newNotifications'

import {createReducer} from './utils'
import actionQueue from './middleware/actionQueue'

export default (config = {}, preferences = {}) => {
  const resourcesReducer = createReducer(
    getResourcesInitialState(),
    ResourcesReducers
  )

  const authReducer = createReducer(getAuthInitialState(), AuthReducers)

  const documentConfig = createReducer(
    getDocumentConfigInitialState(config.pages.DocumentConfig),
    DocumentConfigReducers
  )

  const crmUserGroups = createReducer(
    getCRMUserGroupsIntialState(config.pages.crmUserGroups),
    CRMUserGroupsReducers
  )

  const financingUserGroups = createReducer(
    getFinancingUserGroupsInitialState(config.pages.financingUserGroups),
    financingUserGroupsReducers
  )

  const inspectionReports = createReducer(
    getInspectionReportsInitialState({
      ...config.pages.inspectionReports,
      // ...preferences.inspectionReports,
      pageSetup: config.pages.inspectionReports
    }),
    InspectionReportsReducers
  )

  const userReducer = createReducer(
    getUserInitialState(config, preferences),
    UserReducers
  )

  const crmReducers = crmSetup(config.pages.CRM)

  const payslips = createReducer(
    getPayslipsIntialState(config.pages.payslips, preferences),
    PayslipsReducer
  )

  const payslipsNew = createReducer(
    getPayslipsNewIntialState(config.pages.payslips, preferences),
    CreatePayslipReducer
  )

  const ownershipTransfer = createReducer(
    getOwnershipTransferState(config.pages.carOwnership, preferences),
    ownershipTransfers
  )

  const configReducer = createReducer(
    {
      ...getConfigInitialState({
        ...config,
        ...preferences.config
      }),
      cache: {}
    },
    ConfigReducers
  )

  const global = createReducer(
    {
      ...getGlobalInitialState(),
      menuItems: config.menuItems,
      appTitle: config.appTitle
    },
    GlobalReducers
  )

  const signal = createReducer(getSignalInitialState(), SignalReducers)

  const locationManagement = createReducer(
    getLocationInitialState(config.pages.locationManagement.LocationList),
    locationReducers
  )

  const calendar = createReducer(
    {...getCalendarInitialState()},
    CalendarReducers
  )

  // const cardata = createReducer(
  //   {...getCarDataInitialState(config.pages.carDataConfig.CarDataList)},
  //   CarDataReducers
  // )

  const webhook = createReducer(
    {...getWebhookInitialState(config.pages.webhookConfig)},
    WebhookReducers
  )

  const businessPartner = createReducer(
    {
      ...getBusinessPartnerInitialState(
        config.pages.b2bDealConfigV2.businessPartner
      )
    },
    BusinessPartnerReducers
  )

  // const cardatafiles = createReducer(
  //   {
  //     ...getCarDataFilesInitialState(config.pages.carDataConfig.CarDataFileList)
  //   },
  //   carDataFilesReducers
  // )

  const category = createReducer(
    {
      ...getCategoryInitialState(config.pages.category)
    },
    CategoryReducers
  )

  const appointmentCalendarReducers = createReducer(
    getAppointmentCalendarInitialState(),
    AppointmentCalendarReducers
  )

  const bulkUpload = createReducer(
    {...getBulkUploadInitialState(config.pages.bulkUpload)},
    BulkUploadReducers
  )

  const countryOption = createReducer(
    {...getCountryOptionIntialState(config.pages.b2bDealConfig.countryOption)},
    CountryOptionReducer
  )

  const driver = createReducer(
    {...getDriverIntialState(config.pages.drivers)},
    DriverReducer
  )

  const transitJob = createReducer(
    {...getTransitJobIntialState(config.pages.transit)},
    TransitJobReducer
  )

  const participant = createReducer(
    {...getParticipantInitialState(config.pages.participant)},
    participantReducers
  )

  const auctionSchedules = createReducer(
    {
      ...getAuctionsSchedulesInitialState(
        config.pages.auctionsManagement.auctionSchedules
      )
    },
    AuctionsSchedulesReducer
  )

  const notificationInbox = createReducer(
    getNotificationsInitialState(),
    NotificationsReducers
  )

  const extension =
    typeof window === 'object' &&
    typeof window.devToolsExtension !== 'undefined' &&
    (isEmpty(process.env) || process.env.NODE_ENV !== 'production')
      ? window.devToolsExtension()
      : (f) => f

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({name: 'Admin App v2'})
      : compose
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    combineReducers({
      resources: resourcesReducer,
      global,
      config: configReducer,
      // cardata,
      bulkUpload,
      category,
      // cardatafiles,
      authReducer,
      userReducer,
      inspectionReports,
      locationManagement,
      calendar,
      documentConfig,
      crmUserGroups,
      financingUserGroups,
      signal,
      webhook,
      appointmentCalendar: appointmentCalendarReducers,
      payslips,
      payslipsNew,
      countryOption,
      driver,
      transitJob,
      ownershipTransfer,
      participant,
      auctionSchedules,
      businessPartner,
      notificationInbox,
      ...crmReducers
    }),
    composeEnhancers(
      applyMiddleware(thunk, actionQueue, sagaMiddleware),
      extension
    )
  )

  sagaMiddleware.run(rootSagas)

  return store
}
