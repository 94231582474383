import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  loadInbox,
  loadNext,
  notificationInboxClose,
  notificationInboxNotShow,
  notificationInboxSetFilter,
  archiveNotification,
  clearAll
} from '../../store/newNotifications/actions'
import IconClose from '../../assets/images/icons/close-button.svg'
import './Notification.styl'
import cookies from 'browser-cookies'
import {compose} from 'redux'
import translate from 'react-i18next/dist/commonjs/translate'
import debounce from 'lodash/debounce'
import NotificationList from './NotificationList'
import {withRouter} from 'react-router'

export class NewNotification extends Component {
  constructor(props) {
    super(props)
    this.closeNotificationInbox = this.closeNotificationInbox.bind(this)
    this.onAllClick = this.onAllClick.bind(this)
    this.onArchiveClick = this.onArchiveClick.bind(this)
    this.onClearAllClick = this.onClearAllClick.bind(this)
    this.moveToArchive = this.moveToArchive.bind(this)
    this.onScroll = this.onScroll.bind(this)

    this.state = {
      indicatorStyle: {
        left: 0,
        width: 0
      },
      inboxItems: [],
      totalItemsVisible: 0,
      previousPath: ''
    }
  }

  componentWillMount() {
    this.loadNextDebounced = debounce(this.props.loadNext, 300)
    this.setState({totalItemsVisible: this.state.totalItemsVisible + 1})
  }

  componentDidMount() {
    if (typeof this.allFilterRef !== 'undefined') {
      const width = this.allFilterRef.offsetWidth

      this.setState({indicatorStyle: {width, left: 0}})
    }
    this.props.loadInbox(cookies.get('countryCode'))
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({
        inboxItems: Array.isArray(this.props?.items)
          ? this.props.items.slice(0, 20 * this.state.totalItemsVisible)
          : []
      })
    }

    const currentPath = window.location.pathname

    if (currentPath !== this.state.previousPath) {
      if (currentPath?.includes('login')) {
        this.props.notificationInboxNotShow()
      }
      this.setState({previousPath: currentPath})
      this.props.loadInbox(cookies.get('countryCode'))
    }
  }

  closeNotificationInbox(event) {
    event.preventDefault()

    this.props.notificationInboxClose()
  }

  onAllClick(event) {
    event.preventDefault()
    this.props.setFilter('all', cookies.get('countryCode'))
    const width = event.target.offsetWidth + 2

    this.setState({indicatorStyle: {width, left: 0}})
    this.setState({totalItemsVisible: 1})
  }

  onArchiveClick(event) {
    event.preventDefault()
    this.props.setFilter('archive', cookies.get('countryCode'))
    const width = event.target.offsetWidth + 2
    const margin = parseFloat(
      window.getComputedStyle(this.allFilterRef).marginRight
    )
    const left = this.allFilterRef.offsetWidth + margin - 2

    this.setState({indicatorStyle: {width, left}})
    this.setState({totalItemsVisible: 1})
  }

  onClearAllClick(event) {
    event.preventDefault()

    this.props.clearAll(cookies.get('countryCode'))
  }

  moveToArchive(item) {
    this.props.archiveNotification(item.id)
  }

  onScroll() {
    if (
      this.state.inboxItems?.length ===
      Math.floor(this.state.totalItemsVisible / 5) * 100
    ) {
      this.setState({totalItemsVisible: this.state.totalItemsVisible + 1})
      this.loadNextDebounced(cookies.get('countryCode'))
    } else {
      this.setState({totalItemsVisible: this.state.totalItemsVisible + 1})
      this.setState({
        inboxItems: this.props?.items?.slice(
          0,
          20 * this.state.totalItemsVisible
        )
      })
    }
  }

  renderClearButton() {
    if (this.props.filter === 'archive') {
      return null
    }

    return (
      <a
        href='#'
        className='clearAll'
        onClick={this.onClearAllClick}
        id='headerNotificationClear'
      >
        {this.props.t('notifications.clear')}
      </a>
    )
  }

  render() {
    const classes = ['notificationContainer']
    const {visible, filter} = this.props

    if (visible === true) {
      classes.push('open')
    }
    return (
      <>
        <div className={classes.join(' ')} id='headerNotificationContainer'>
          <div className='titleContainer'>
            <span id='headerNotificationTitle'>
              {this.props.t('notifications.title')}
            </span>
            <a
              href='#'
              onClick={this.closeNotificationInbox}
              className='closeButtonContainer'
              id='headerNotificationClose'
            >
              <IconClose width='20' height='20' color='#002F34' />
            </a>
            <div className='filterContainer'>
              <a
                href='#'
                className={`filterItem ${filter === 'all' ? 'selected' : ''}`}
                onClick={this.onAllClick}
                ref={(ref) => {
                  this.allFilterRef = ref
                }}
                id='headerNotificationAll'
              >
                {this.props.t('notifications.filter.all')}
              </a>
              <a
                href='#'
                className={`filterItem ${
                  filter === 'archive' ? 'selected' : ''
                }`}
                onClick={this.onArchiveClick}
                id='headerNotificationArchive'
              >
                {this.props.t('notifications.filter.archive')}
              </a>
              {this.renderClearButton()}
              <div
                className='filterIndicator'
                style={this.state.indicatorStyle}
              />
            </div>
          </div>
          <NotificationList
            data={this.state.inboxItems}
            onMoveToArchive={(item) => {
              this.moveToArchive(item)
            }}
            onScrollBottom={() => this.onScroll()}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.notificationInbox
})
const mapDispatchToProps = (dispatch) => ({
  notificationInboxClose: () => dispatch(notificationInboxClose()),
  notificationInboxNotShow: () => dispatch(notificationInboxNotShow()),
  setFilter: (filter, country) =>
    dispatch(notificationInboxSetFilter(filter, country)),
  loadInbox: (country) => dispatch(loadInbox(country)),
  archiveNotification: (id) => dispatch(archiveNotification(id)),
  loadNext: (country) => dispatch(loadNext(country)),
  clearAll: (country) => dispatch(clearAll(country))
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate()
)(NewNotification)
