import React from 'react'

import translate from 'react-i18next/dist/commonjs/translate'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Gavel from '@material-ui/icons/Gavel'
import SwapHorizontalCircle from '../../../assets/images/icons/swapHorizontalCircle.svg'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import styled from 'styled-components'
import {formatDateTime} from '../../../utils/dateFormatting'
import IconEmojiTransportation from '../../../assets/images/icons/IconEmojiTransportation.svg'

export const IconBadge = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.55em;
  background-color: ${({color}) => color};
`

const getAuctionIconsConfig = () => {
  const type = ['Ended', 'Started']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`Auction${item}`]: {
        color: 'rgba(255, 152, 0, 0.25)',
        Icon: Gavel
      }
    }),
    {}
  )
}

const getStatutsUpdateIconsConfig = () => {
  const type = ['Available', 'Sold', 'Returned']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`StatusUpdated${item}`]: {
        color: 'rgba(233, 30, 99, 0.25)',
        Icon: SwapHorizontalCircle
      }
    }),
    {}
  )
}

const getOfferPricingUpdatedIconsConfig = () => {
  const type = ['MinOffer', 'MaxOffer', 'TokenPrice', 'AuctionOffer']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`OfferPricingUpdated${item}`]: {
        color: 'rgba(76, 175, 80, 0.25)',
        Icon: MonetizationOn
      }
    }),
    {}
  )
}

const getOfferPricingCreatedIconsConfig = () => {
  const type = ['MinOffer', 'MaxOffer', 'TokenPrice', 'AuctionOffer']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`OfferPricingCreated${item}`]: {
        color: 'rgba(76, 175, 80, 0.25)',
        Icon: MonetizationOn
      }
    }),
    {}
  )
}

export const iconsConfig = {
  CarCreated: {
    color: 'rgba(132, 220, 93, 0.25)',
    Icon: DirectionsCar
  },
  CarUpdatedHandoverFromSellerAt: {
    color: 'rgba(	156, 39, 176, 0.25)',
    Icon: IconEmojiTransportation
  },
  ...getAuctionIconsConfig(),
  ...getStatutsUpdateIconsConfig(),
  ...getOfferPricingUpdatedIconsConfig(),
  ...getOfferPricingCreatedIconsConfig()
}

const BaseTemplate = ({item}) => (
  <div>
    <pre className='notificationContent'>{item.metaInfo.template}</pre>
    <div className='timestamp'>{formatDateTime(new Date(item.createdAt))}</div>
  </div>
)

export const buildActionLink = ({item, localeCode}) =>
  ['', localeCode, 'car', 'edit', item.referenceId].join('/')

export const Template = translate()(BaseTemplate)

export default {
  Template,
  buildActionLink
}
