export const notificationInboxOpen = 'NotificationInbox/OPEN'
export const notificationInboxClose = 'NotificationInbox/CLOSE'
export const notificationInboxNotShow = 'NotificationInbox/NOT_SHOW'
export const notificationInboxSetFilter = 'NotificationInbox/SET_FILTER'
export const notificationInboxDataLoaded = 'NotificationInbox/DATA_LOADED'
export const notificationInboxArchiveMessage =
  'NotificationInbox/ARCHIVE_MESSAGE'
export const notificationInboxLoadingMessages =
  'NotificationInbox/LOADING_MESSAGE'
export const notificationInboxAddMessage = 'NotificationInbox/ADD_MESSAGE'
