const state = {
  visible: false,
  filter: 'all',
  items: [],
  loading: false
}

export const getInitialState = () => ({
  ...state
})
