import PropTypes from 'prop-types'
import React, {useRef, useEffect, useCallback} from 'react'
import NotificationListItem from './NotificationListItem'
import {Task, TemplateNotificationBase} from './NotificationListItems'
import withNotificationTemplate from './withNotificationTemplate'

const NotificationTask = withNotificationTemplate(Task)(NotificationListItem)

const TemplateNotification = withNotificationTemplate(TemplateNotificationBase)(
  NotificationListItem
)

const NotificationList = ({data, onMoveToArchive, onScrollBottom}) => {
  const listRef = useRef(null)

  const handleScroll = useCallback(() => {
    if (!listRef.current) return

    const {scrollTop, scrollHeight, clientHeight} = listRef.current
    if (scrollHeight - scrollTop <= clientHeight + 1) {
      onScrollBottom()
    }
  }, [onScrollBottom])

  useEffect(() => {
    const currentListRef = listRef.current
    if (currentListRef) {
      currentListRef.addEventListener('scroll', handleScroll)

      return () => {
        currentListRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <div ref={listRef} className='notificationList'>
      {data.map((item, index) => {
        if (
          item.referencedEntity === 'Comment' ||
          item.referencedEntity === 'NotificationTask'
        ) {
          return (
            <NotificationTask
              item={item}
              key={`notification_${index}`}
              onMoveToArchive={onMoveToArchive}
            />
          )
        }

        return (
          <TemplateNotification
            item={item}
            key={`notification_${index}`}
            onMoveToArchive={onMoveToArchive}
          />
        )
      })}
    </div>
  )
}

NotificationList.propTypes = {
  data: PropTypes.array,
  onMoveToArchive: PropTypes.func,
  onScrollBottom: PropTypes.func // New prop for bottom scroll detection
}

export default NotificationList
