import moment from 'moment'
import cookies from 'browser-cookies'

export const convertToUTC = (value) => {
  return value.from && value.to
    ? {
        from: moment(value.from)
          .utc()
          .format(),
        to: moment(value.to)
          .utc()
          .format()
      }
    : moment(value)
        .utc()
        .format()
}

export const formatDate = (value, format) =>
  moment.isMoment(value)
    ? value.format(format)
    : moment(value).isValid()
    ? moment(value).format(format)
    : value

const getMonthName = (date) => {
  let locale = 'id-ID'
  if (cookies.get('locale') === 'id') {
    locale = 'id-ID'
  } else if (cookies.get('locale') === 'en-id') {
    locale = 'en-US'
  }
  return date.toLocaleString(locale, {month: 'long'})
}

export const formatDateShortMonth = (date, separator = ' ') => {
  return [
    `0${date.getDate()}`.slice(-2),
    `${getMonthName(date)}`,
    date.getFullYear()
  ].join(separator)
}

export const formatTime = (date) => {
  if (date === null) {
    return ''
  }
  const minutes = `0${date.getMinutes()}`.slice(-2)
  const hours = `0${date.getHours()}`.slice(-2)

  return [hours, minutes].join(':')
}

export const formatDateTime = (date) => {
  return [formatDate(date), formatTime(date)].join(' ')
}

export const formatDateWithSeparator = (date, separator = '/') => {
  return [
    `0${date.getDate()}`.slice(-2),
    `0${date.getMonth() + 1}`.slice(-2),
    date.getFullYear()
  ].join(separator)
}
