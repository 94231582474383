import './style/style.styl'
import {CssBaseline} from '@material-ui/core'
import Header from './components/layout/Header'
import Content from './components/layout/Content'
import RouteContainer from './pages/RouteContainer'
import {ConfigProvider} from './components/providers/withConfig'
import {UserProvider} from './components/providers/withAccessManagement'
import {Fragment, Component} from 'react'
import Notification from './components/Notifications/Notification'

export default class Layout extends Component {
  componentDidCatch(error) {
    /* eslint-disable */
    console.error(error)
    /* eslint-enable */
  }

  render() {
    return (
      <Fragment>
        <CssBaseline />
        <ConfigProvider>
          <UserProvider>
            <Header />
            <Content>
              <RouteContainer />
              <Notification />
            </Content>
          </UserProvider>
        </ConfigProvider>
      </Fragment>
    )
  }
}
