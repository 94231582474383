import {PureComponent} from 'react'
import {SlideUp} from '../../../components/Animations/Slides'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
  withMobileDialog
} from '@material-ui/core'
import {translate} from 'react-i18next'
import dialogStyles from '../common/dialogStyles'

class Preview extends PureComponent {
  render() {
    const {t, comment} = this.props

    return (
      <div>
        <Dialog
          fullWidth
          open={this.props.isOpen}
          TransitionComponent={SlideUp}
          maxWidth='sm'
          onClose={this.props.handleClose}
          id='form-dialog'
        >
          <DialogTitle id='form-dialog-title'>
            {t('appointment.comments.label')}
          </DialogTitle>
          <DialogContent id='form-dialog-content'>
            <Typography
              variant='body2'
              color='inherit'
              className='commentText'
              id='form-dialog-content-text'
            >
              {comment}
            </Typography>
            <DialogActions id='form-dialog-content-action'>
              <Button
                onClick={this.props.handleClose}
                color='primary'
                id='form-dialog-content-button'
              >
                {t('button.cancel')}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default translate()(
  withMobileDialog()(withStyles(dialogStyles)(Preview))
)
