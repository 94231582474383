import {archiveMessage, inbox} from '../../api/contact/requests'
import * as types from './types'

const setNotificationLastAccess = (date) => {
  window.localStorage.setItem('notificationLastAccess', date)
}

const getNotificationLastAccess = () =>
  window.localStorage.getItem('notificationLastAccess')

export const notificationInboxOpen = () => {
  setNotificationLastAccess(new Date())

  return {type: types.notificationInboxOpen}
}

export const notificationInboxClose = () => {
  setNotificationLastAccess(new Date())

  return {
    type: types.notificationInboxClose
  }
}

export const notificationInboxNotShow = () => {
  return {
    type: types.notificationInboxNotShow
  }
}

export const notificationInboxToggle = () => (dispatch, getState) => {
  const {notificationInbox} = getState()

  if (notificationInbox?.visible === true) {
    dispatch(notificationInboxClose())
  } else {
    dispatch(notificationInboxOpen())
  }
}

export const notificationInboxSetFilter = (payload, country) => (dispatch) => {
  dispatch({
    type: types.notificationInboxSetFilter,
    payload
  })

  dispatch(loadInbox(country))
}

export const loadInbox = (country, startsFrom = null, append = false) => (
  dispatch,
  getState
) => {
  const {notificationInbox} = getState()
  const lastAccessDateValue = getNotificationLastAccess()
  const lastAccessDate =
    lastAccessDateValue !== null ? new Date(lastAccessDateValue) : null
  const to = startsFrom !== null ? +startsFrom : +new Date()

  dispatch({type: types.notificationInboxLoadingMessages})

  return inbox({
    timeRange: {to},
    archived: notificationInbox.filter === 'archive',
    country
  }).then((items) => {
    let payload = items?.inbox?.map((item) => ({
      ...item,
      isRead:
        lastAccessDate !== null && lastAccessDate > new Date(item?.updatedAt)
    }))

    if (append === true) {
      payload = [...notificationInbox?.items, ...payload]
    }

    dispatch({
      type: types.notificationInboxDataLoaded,
      payload
    })
  })
}

export const loadNext = (country) => (dispatch, getState) => {
  const {notificationInbox} = getState()

  const startFrom =
    notificationInbox?.items[notificationInbox.items.length - 1]?.createdAt

  dispatch(loadInbox(country, new Date(startFrom), true))
}

export const archiveNotification = (id) => (dispatch) => {
  return archiveMessage([id]).then(() => {
    dispatch({
      type: types.notificationInboxArchiveMessage,
      payload: id
    })
  })
}

export const clearAll = (country) => (dispatch, getState) => {
  const {notificationInbox} = getState()

  return archiveMessage(notificationInbox?.items?.map((item) => item?.id)).then(
    () => {
      dispatch(loadInbox(country))
    }
  )
}

export const addMessage = (message) => ({
  type: types.notificationInboxAddMessage,
  payload: {
    ...message,
    isRead: false
  }
})
