import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IconArrowRight from '../../assets/images/icons/arrow-right.svg'
import IconClose from '../../assets/images/icons/close-button.svg'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import Gavel from '@material-ui/icons/Gavel'
import SwapHorizontalCircle from '../../assets/images/icons/swapHorizontalCircle.svg'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import ReportProblem from '@material-ui/icons/ReportProblem'
import Assignment from '@material-ui/icons/Assignment'
import IconEmojiTransportation from '../../assets/images/icons/IconEmojiTransportation.svg'
import IconAlternateEmail from '../../assets/images/icons/IconAlternateEmail.svg'
import IconNotificationTask from '../../assets/images/icons/IconNotificationTask.svg'
import IconAssignTaskNotification from '../../assets/images/icons/IconAssignTaskNotification.svg'
import IconGreatOffer from '../../assets/images/icons/IconGreatOffer.svg'
import styled from 'styled-components'

const IconBadge = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.55em;
  background-color: ${({color}) => color};

  svg {
    width: 32px;
    height: 32px;
  }
`

const LeftIndicator = styled.div`
  width: 5px;
  background-color: ${({color}) => color};
  height: 100%;
  position: absolute;
  left: 0;
`

const NotificationItem = styled.div`
  ${({backgroundColor}) => `background-color: ${backgroundColor}`}
`

const getAuctionIconsConfig = () => {
  const type = ['Ended', 'Started']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`Auction${item}`]: {
        color: 'rgba(255, 152, 0, 0.25)',
        Icon: Gavel,
        backgroundColor: 'rgba(255, 152, 0, 0.15)',
        leftIndicatorColor: 'rgb(63,81,181)'
      }
    }),
    {}
  )
}

const getStatutsUpdateIconsConfig = () => {
  const type = ['Available', 'Sold', 'Returned']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`StatusUpdated${item}`]: {
        color: 'rgba(233, 30, 99, 0.25)',
        Icon: SwapHorizontalCircle,
        backgroundColor: 'rgba(233, 30, 99, 0.15)',
        leftIndicatorColor: 'rgb(63,81,181)'
      }
    }),
    {}
  )
}

const getOfferPricingUpdatedIconsConfig = () => {
  const type = ['MinOffer', 'MaxOffer', 'TokenPrice', 'AuctionOffer']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`OfferPricingUpdated${item}`]: {
        color: 'rgba(76, 175, 80, 0.25)',
        Icon: MonetizationOn,
        backgroundColor: 'rgba(76, 175, 80, 0.15)',
        leftIndicatorColor: 'rgb(63,81,181)'
      }
    }),
    {}
  )
}

const getOfferPricingCreatedIconsConfig = () => {
  const type = ['MinOffer', 'MaxOffer', 'TokenPrice', 'AuctionOffer']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`OfferPricingCreated${item}`]: {
        color: 'rgba(76, 175, 80, 0.25)',
        Icon: MonetizationOn,
        backgroundColor: 'rgba(76, 175, 80, 0.15)',
        leftIndicatorColor: 'rgb(63,81,181)'
      }
    }),
    {}
  )
}

const getCommentIconsConfig = () => {
  const type = ['Created', 'Updated']

  return type.reduce(
    (acc, item) => ({
      ...acc,
      [`Comment${item}`]: {
        color: 'rgba(110, 216, 255, 0.25)',
        Icon: IconAlternateEmail,
        backgroundColor: 'rgba(110, 216, 255, 0.15)',
        leftIndicatorColor: 'rgb(63,81,181)'
      }
    }),
    {}
  )
}

const PaymentProofCreatedConfig = () => ({
  PaymentProofCreated: {
    Icon: Assignment,
    color: 'rgba(48, 39, 176, 0.25)',
    backgroundColor: 'rgba(48, 39, 176, 0.15)',
    leftIndicatorColor: 'rgb(63,81,181)'
  }
})

const itemConfig = {
  CarCreated: {
    color: 'rgba(132, 220, 93, 0.25)',
    Icon: DirectionsCar,
    backgroundColor: 'rgba(132, 220, 93, 0.15)',
    leftIndicatorColor: 'rgb(63,81,181)'
  },
  CarUpdatedHandoverFromSellerAt: {
    color: 'rgba(156, 39, 176, 0.25)',
    Icon: IconEmojiTransportation,
    backgroundColor: 'rgba(156, 39, 176, 0.15)',
    leftIndicatorColor: 'rgb(63,81,181)'
  },
  DealerOfferPriceCreatedNotification: {
    color: 'rgba(110, 216, 255, 0.25)',
    Icon: IconGreatOffer,
    backgroundColor: 'rgba(110, 216, 255, 0.15)',
    leftIndicatorColor: 'rgb(63,81,181)'
  },
  InspectionCreatedNotification: {
    color: 'rgba(110, 216, 255, 0.25)',
    Icon: IconNotificationTask,
    backgroundColor: 'rgba(110, 216, 255, 0.15)',
    leftIndicatorColor: 'rgb(63,81,181)'
  },
  AssignTaskNotification: {
    color: 'rgba(110, 216, 255, 0.25)',
    Icon: IconAssignTaskNotification,
    backgroundColor: 'rgba(110, 216, 255, 0.15)',
    leftIndicatorColor: 'rgb(63,81,181)'
  },
  ...getAuctionIconsConfig(),
  ...getStatutsUpdateIconsConfig(),
  ...getOfferPricingUpdatedIconsConfig(),
  ...getOfferPricingCreatedIconsConfig(),
  ...getCommentIconsConfig(),
  ...PaymentProofCreatedConfig()
}

const defaultConfig = {
  Icon: ReportProblem,
  color: 'rgba(128,128,128, 0.25)',
  backgroundColor: 'rgba(128,128,128, 0.15)',
  leftIndicatorColor: 'rgb(63,81,181)'
}

const getLinkTarget = (props) =>
  props.openInNewTab ? {target: '_blank'} : {target: '_self'}

class NotificationListItem extends Component {
  constructor(props) {
    super(props)

    this.moveToArchive = this.moveToArchive.bind(this)
  }

  moveToArchive(event) {
    event.stopPropagation()
    event.preventDefault()

    this.props.onMoveToArchive(this.props.item)

    return false
  }

  buildActionLink() {
    const props = {
      ...this.props,
      ...this.context
    }

    if (this.props.buildActionLink instanceof Function) {
      return this.props.buildActionLink(props)
    }

    return '/'
  }

  render() {
    const {isRead, archived} = this.props.item
    const itemClasses = ['notificationListItem']
    const config = itemConfig[this.props.item.type] || defaultConfig
    const {Icon, color, backgroundColor, leftIndicatorColor} = config
    let itemBackgroundColor = ''

    if (archived === true) {
      itemClasses.push('archived')
    } else if (isRead === true) {
      itemClasses.push('read')
    } else {
      itemBackgroundColor = backgroundColor
    }

    const linkTarget = getLinkTarget(this.props)

    return (
      <NotificationItem
        className={itemClasses.join(' ')}
        backgroundColor={itemBackgroundColor}
        id='notifListItem'
      >
        <LeftIndicator
          color={leftIndicatorColor}
          id='notifContentLeftIndicator'
        />
        <IconBadge color={color} id='notifContentIcon'>
          <Icon />
        </IconBadge>
        <a
          className='notificationItemDetails'
          href={this.buildActionLink()}
          {...linkTarget}
          id='notifContentDetailWrapper'
        >
          {this.props.children}
        </a>
        <div className='actionIcon'>
          <a className='goToCarIcon' href='#' id='notifContentButtonArrow'>
            <IconArrowRight />
          </a>
          <a
            className='moveToArchiveIcon'
            href='#'
            onClick={this.moveToArchive}
            id='notifContentButtonArchive'
          >
            <IconClose width='20' height='20' color='#002F34' />
          </a>
        </div>
      </NotificationItem>
    )
  }
}

NotificationListItem.propTypes = {
  item: PropTypes.object,
  onMoveToArchive: PropTypes.func
}

NotificationListItem.contextTypes = {
  localeCode: PropTypes.string
}

export default NotificationListItem
